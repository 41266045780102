<template>
    <div class="join-us">
        <div class="banner color-black">
            <div class="content">
                <div class="title font-bold">
                    加入工會
                    <div class="red-line-l"></div>
                </div>
                <div class="text">
                    需具備配音員及配音相關行業，且無一定雇主的身份，申請入會時應填妥申請表，需有兩位理監事推薦，提經理、監事會審查合格，並繳納會費與入會費後，即可成為本會會員。
                </div>
                <div class="btn-box">
                    <router-link class="red-btn btn" to="/data-download"
                        >立即下載入會申請書</router-link
                    >
                    <div class="white-btn btn" @click="scrollTo('flow')">
                        入會流程一點通
                    </div>
                </div>
            </div>
        </div>
        <div class="flow" ref="flow">
            <div class="content">
                <div class="title font-bold">
                    入會流程
                    <div class="red-line"></div>
                </div>
                <div class="flow-content">
                    <div
                        class="item-block"
                        v-for="(item, index) in flows"
                        :key="item.text"
                    >
                        <div class="arrow" v-if="index != 0"></div>
                        <div class="item">
                            <div class="icon">
                                <img :src="item.icon" alt="" />
                            </div>
                            <div
                                class="text font-bold"
                                v-html="item.text"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="explain">
            <div class="board">
                <div class="explain-block">
                    <div class="title font-bold">
                        入會申請文件
                        <div class="red-line"></div>
                    </div>
                    <div class="description">
                        1.入會申請書 <br />
                        2.身份證正反面影本 <br />
                        3.費用：投保薪資依政府規定之最低基本薪資$26,400元計算，其他薪資可洽詢本會<br />
                        4.繳費證明：匯款單或ATM轉帳明細表<br />
                        ※如有眷屬依附，請附眷屬資料（身份證或戶口名簿影本），每眷以本人之健保費加計，第四口眷（含）以上免計費。（請下載附加眷屬申請表）
                        <br />
                    </div>
                </div>
                <div class="explain-block">
                    <div class="title font-bold">
                        入會方式
                        <div class="red-line"></div>
                    </div>
                    <div class="description">
                        請先備妥上述應備文件，並於上班時間來電與會務人員聯絡後續相關入會事宜。
                    </div>
                </div>
                <div class="explain-block">
                    <div class="title font-bold">
                        會費計算
                        <div class="red-line"></div>
                    </div>
                    <div class="description">
                        1.會員入會費$1,500。<br />
                        2.會員年費每年$2,000，每年6月30日前加入需繳交全年費。<br />
                        7月後加入者繳交$1,000年費，並不得領取當年紀念品。
                    </div>
                </div>
                <div class="explain-block">
                    <div class="title font-bold">
                        會員福利
                        <div class="red-line"></div>
                    </div>
                    <div class="description">
                        1.聲音資料庫。<br />
                        2.急難慰問制度。<br />
                        3.五一發放紀念品。<br />
                        4.尾牙或春酒。<br />
                        5.在職進修收費或免費課程。<br />
                        6.開發各種業務項目。<br />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            flows: [
                /* eslint-disable global-require */
                { icon: require('../assets/join-us/flow-icon-1.png'), text: '需為配音員、及配音相關行業且無一定雇主' },
                { icon: require('../assets/join-us/flow-icon-2.png'), text: '填具申請表，<br>繳納相關資料' },
                { icon: require('../assets/join-us/flow-icon-3.png'), text: '需2位理監事推薦，<br>並經審查合格' },
                { icon: require('../assets/join-us/flow-icon-4.png'), text: '繳納會費與入會費' },
                { icon: require('../assets/join-us/flow-icon-5.png'), text: '完成入會申請' },
            ],
        };
    },
    computed: {},
    created() { },
    mounted() { },
    methods: {
        scrollTo(ref) {
            const target = this.$refs[ref];
            window.scrollTo({ top: target.offsetTop, behavior: 'smooth' });
        },
    },
};
</script>

<style lang="scss" scoped>
.content {
    width: 80vw;
    max-width: 1140px;
}
.banner {
    background-image: url('../assets/join-us/banner.jpg');
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
        font-size: 40px;
        line-height: 57px;
        margin-bottom: 47px;
    }

    .text {
        font-size: 20px;
        line-height: 30px;
        max-width: 650px;
        margin-bottom: 47px;
    }

    .btn-box {
        display: flex;
        .btn {
            font-size: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 254px;
            height: 44px;
            cursor: pointer;
            transition: all 0.3s;
            position: relative;
            text-decoration: none;
        }

        .btn:not(:first-child) {
            margin-left: 23px;
        }

        .btn::after {
            transition: all 0.3s;
        }

        .btn::before,
        .btn::after {
            background: rgba($color: #dedede, $alpha: 0.3);
            content: '';
            position: absolute;
            border-radius: 6px;
        }

        .btn::after {
            height: 0;
            left: 0;
            top: 0;
            width: 100%;
        }

        .btn:hover:after {
            height: 100%;
        }
    }
}

.flow {
    padding: 130px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
        font-size: 20px;
        margin-bottom: 80px;
    }
    .flow-content {
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 1140px;
        flex-wrap: wrap;
        .item-block {
            display: flex;
            justify-content: center;
        }

        .item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .icon {
                width: 180px;
                height: 180px;
                border-radius: 100%;
                background: #f9fafb;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 34px;

                img {
                    height: 50%;
                }
            }

            .text {
                font-size: 18px;
                line-height: 30px;
                width: 180px;
                text-align: center;
            }
        }

        .arrow {
            width: 15px;
            height: 23px;
            background-image: url('../assets/join-us/arrow-right.png');
            background-size: cover;
            margin: 90px 20px 0 20px;
        }
    }
}

.explain {
    background-image: url('../assets/join-us/explain-bg.jpg');
    background-size: cover;
    background-position: 50% 50%;
    padding: 109px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .board {
        width: 79vw;
        max-width: 1134px;
        background-color: #f9fafb;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        padding: 52px 7%;
        .explain-block {
            display: flex;
        }

        .explain-block:not(:last-child) {
            margin-bottom: 50px;
        }
        .title {
            width: 120px;
            font-size: 20px;
            flex-shrink: 0;
            margin-right: 48px;
        }

        .description {
            font-size: 16px;
            line-height: 30px;
        }
    }
}

@media screen and (max-width: 1420px) {
    .flow {
        padding: 130px 0 52px 0;
        .title {
            width: 100%;
        }
        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .flow-content {
            width: 870px;

            .item {
                margin-bottom: 78px;
            }
        }
    }
}

@media screen and (max-width: 960px) {
    .banner {
        background-image: url('../assets/join-us/banner-mobile.jpg');
        .title {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 28px;
        }

        .text {
            font-size: 16px;
            background-color: rgba(249, 250, 251, 0.63);
            border-radius: 8px;
            padding: 19px 17px;
        }

        .btn-box {
            flex-direction: column;
            align-items: center;

            .btn:not(:first-child) {
                margin-left: 0;
                margin-top: 20px;
            }
        }
    }
    .flow {
        padding: 63px 0;
        .title {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 24px;
        }
        .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .flow-content {
            width: 180px;

            .item-block {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .item {
                margin-bottom: 0;
            }

            .arrow {
                width: 23px;
                height: 15px;
                margin: 39px 0 39px 0;
                background-image: url('../assets/join-us/arrow-down.png');
            }
        }
    }
    .explain {
        background-image: url('../assets/join-us/explain-bg-mobile.jpg');
        padding: 50px 0;
        .board {
            width: 90%;
            .explain-block {
                flex-direction: column;
                align-items: center;

                .title {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-right: 0;
                    margin-bottom: 22px;
                }
            }
        }
    }
}
</style>
